import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Rating } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "rating"
    }}>{`Rating`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Rating from '@mfrm/mfcl/Rating'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Rating} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Rating name=\"basic\" defaultValue={2} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Rating,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Rating name="basic" defaultValue={2} mdxType="Rating" />
    </Playground>
    <h2 {...{
      "id": "precision"
    }}>{`Precision`}</h2>
    <p>{`Use precision to create half ratings`}</p>
    <Playground __position={2} __code={'<div>\n  <Rating name=\"precision-rating\" defaultValue={1.5} precision={0.5} />\n</div>\n<div>\n  <Rating name=\"precision-rating\" defaultValue={2.6} precision={0.1} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Rating,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <Rating name="precision-rating" defaultValue={1.5} precision={0.5} mdxType="Rating" />
  </div>
  <div>
    <Rating name="precision-rating" defaultValue={2.6} precision={0.1} mdxType="Rating" />
  </div>
    </Playground>
    <h2 {...{
      "id": "rating-types"
    }}>{`Rating Types`}</h2>
    <Playground __position={3} __code={'<div>\n  <Rating ratingType=\"numeric\" defaultValue={3} />\n</div>\n<br />\n<br />\n<div>\n  <Rating defaultValue={2} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Rating,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <Rating ratingType='numeric' defaultValue={3} mdxType="Rating" />
  </div>
  <br />
  <br />
  <div>
    <Rating defaultValue={2} mdxType="Rating" />
  </div>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Playground __position={4} __code={'<div>\n  <Rating name=\"size-sm\" defaultValue={2} size=\"sm\" />\n</div>\n<div>\n  <Rating name=\"size-md\" defaultValue={2} />\n</div>\n<div>\n  <Rating name=\"size-lg\" defaultValue={2} size=\"lg\" />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Rating,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <Rating name="size-sm" defaultValue={2} size="sm" mdxType="Rating" />
  </div>
  <div>
    <Rating name="size-md" defaultValue={2} mdxType="Rating" />
  </div>
  <div>
    <Rating name="size-lg" defaultValue={2} size="lg" mdxType="Rating" />
  </div>
    </Playground>
    <h2 {...{
      "id": "disabled-and-readonly"
    }}>{`Disabled and readOnly`}</h2>
    <Playground __position={5} __code={'<div>\n  Read Only\n  <Rating name=\"read-only\" value={3} readOnly />\n</div>\n<div>\n  Disabled\n  <Rating name=\"disabled\" value={2} disabled />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Rating,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    Read Only
    <Rating name="read-only" value={3} readOnly mdxType="Rating" />
  </div>
  <div>
    Disabled
    <Rating name="disabled" value={2} disabled mdxType="Rating" />
  </div>
    </Playground>
    <h2 {...{
      "id": "controlled-rating"
    }}>{`Controlled Rating`}</h2>
    <Playground __position={6} __code={'<Component initialState={{ value: 2 }}>\n  {({ setState, state }) => (\n    <div>\n      Controlled\n      <Rating\n        name=\"simple-controlled\"\n        value={state.value}\n        onChange={(event, newValue) => {\n          setState({ value: newValue })\n        }}\n      />\n    </div>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Rating,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        value: 2
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <div>
        Controlled
        <Rating name="simple-controlled" value={state.value} onChange={(event, newValue) => {
            setState({
              value: newValue
            });
          }} mdxType="Rating" />
      </div>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      